<style lang="less">
.turn-content {
    .el-slider__runway,
    .el-slider__bar {
        height: 3px;
    }
    .el-slider__runway {
        margin: 10px 0;
    }
    .el-slider__button {
        width: 6px;
        height: 6px;
    }
    .el-slider__button-wrapper {
        top: -10px;
        height: 20px;
        width: 20px;
    }
    .el-select {
        width: 120px;
    }
    // .el-radio {
    //     width: 90px;
    // }
}
</style>
<template>
    <div class="turn-content">
        <div class="textarea">
            <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 4 }"
                placeholder="请输入内容"
                resize="none"
                v-model="turn.text"
                maxlength="300"
                show-word-limit
            >
            </el-input>
        </div>
        <div class="voice-box">
            <el-tabs v-model="tabs_active" @tab-click="handleTbas">
                <el-tab-pane label="中文" name="1">
                    <div
                        class="voice-cate"
                        v-for="(item, index) in voice[0].cate"
                        :key="index"
                    >
                        <div class="cate-title">{{ item.lable }}</div>
                        <div class="voice-list">
                            <div
                                class="voice-item"
                                v-for="(k, i) in item.voice"
                                :key="i"
                            >
                                <el-radio
                                    v-model="turn.voice"
                                    :label="k.value"
                                    border
                                    size="mini"
                                    >{{ k.label }}</el-radio
                                >
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="方言" name="2">
                    <div
                        class="voice-cate"
                        v-for="(item, index) in voice[1].cate"
                        :key="index"
                    >
                        <div class="cate-title">{{ item.lable }}</div>
                        <div class="voice-list">
                            <div
                                class="voice-item"
                                v-for="(k, i) in item.voice"
                                :key="i"
                            >
                                <el-radio
                                    v-model="turn.voice"
                                    :label="k.value"
                                    border
                                    size="mini"
                                    >{{ k.label }}</el-radio
                                >
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="turn-set">
            <div class="turn-slider">
                <div class="set-item">
                    <dl>
                        <dt>情感演绎</dt>
                        <dd class="slect-box">
                            <el-select
                                v-model="deduction"
                                placeholder="请选择"
                                size="mini"
                            >
                                <el-option
                                    v-for="item in select_data"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </dd>
                    </dl>
                </div>

                <div class="set-item">
                    <dl class="slider_box">
                        <dt>语速</dt>
                        <dd>
                            <ul class="slider">
                                <li>
                                    <el-slider
                                        v-model="turn.speechRate"
                                        :min="-500"
                                        :max="500"
                                        :step="100"
                                    ></el-slider>
                                </li>
                                <li>
                                    {{ turn.speechRate }}
                                </li>
                            </ul>
                        </dd>
                    </dl>
                </div>
                <div class="set-item">
                    <dl class="slider_box">
                        <dt>音调</dt>
                        <dd>
                            <ul class="slider">
                                <li>
                                    <el-slider
                                        v-model="turn.pitchRate"
                                        :min="-500"
                                        :max="500"
                                        :step="100"
                                    ></el-slider>
                                </li>
                                <li>{{ turn.pitchRate }}</li>
                            </ul>
                        </dd>
                    </dl>
                </div>
                <div class="set-item">
                    <dl class="slider_box">
                        <dt>音量</dt>
                        <dd>
                            <ul class="slider">
                                <li>
                                    <el-slider
                                        v-model="turn.volume"
                                        :min="0"
                                        :max="100"
                                        :step="10"
                                    ></el-slider>
                                </li>
                                <li>{{ turn.volume }}</li>
                            </ul>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        <!-- <div class="btn-box">
            <el-button type="primary" size="small" @click="git_audio"
                >保存</el-button
            >
            <el-button type="primary" size="small" @click="git_audio"
                >播放</el-button
            >
        </div> -->
    </div>
</template>

<script>
import { gitAudio } from "@/api/engi";
import voice from "@/utils/voice";
export default {
    name: "Turn",
    props: {
        trun_txt: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            tabs_active: "1",
            voice: [],
            showModel: false,
            audio_url:
                "362897107360677957/2023-03-24/08ec48a29950432b90d3bf3523eb53ae.mp3",
            turn: {
                text: this.trun_txt, //文本
                voice: "xiaoyun", //发音人
                volume: 100, //音量
                speechRate: 0, //语速 -500 - 500
                pitchRate: 0, //音调 -500 - 500
                format: "mp3",
            },
            deduction: "",
            select_data: [
                {
                    value: 1,
                    label: "助手",
                },
                {
                    value: 2,
                    label: "新闻联播",
                },
                {
                    value: 3,
                    label: "情感",
                },
            ],
            audio_oss: this.$store.state.audio,
        };
    },
    created() {
        //挂载语音数据到实例
        this.voice = voice;
        // console.log(voice);
    },
    mounted() {
        // console.log(voice);
    },

    methods: {
        handleTbas() {
            console.log(111);
        },
        git_audio() {
            if (!this.turn.text) {
                this.$message.error("请输入文本内容！");
                return;
            }
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            gitAudio(this.turn).then((res) => {
                if (res.code == 200) {
                    this.showModel = true;
                    this.audio_url = res.data.fileInfo.fileUrl;
                } else {
                    this.$message.error("对不起，文本转语音失败！");
                }
                loading.close();
            });
            // console.log(this.turn);
        },
        closeModel() {
            this.showModel = false;
            this.audio_url = "";
        },
    },
};
</script>

<style lang="less" scoped>
.turn-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.turn-slider {
    display: flex;
    .set-item {
        &:first-child {
            flex: 0 0 170px;
        }
        flex: 1;
        .slider_box {
            max-width: 200px;
        }
        dt {
            margin-bottom: 10px;
        }
        .slider {
            display: flex;
            align-items: center;
            li:first-child {
                flex: 1;
            }
            li:last-child {
                width: 60px;
                padding: 0 4px;
            }
        }
    }
}
.voice-box,
.turn-set {
    margin-top: 20px;
}
.voice-box {
    flex: 1;
    height: 0;
    overflow-x: auto;
}
.cate-title {
    margin-bottom: 10px;
}
.voice-cate + .voice-cate {
    margin-top: 20px;
}
.voice-list {
    display: flex;
    flex-wrap: wrap;
    .voice-item {
        flex: 0 0 150px;
        margin-bottom: 10px;
    }
}
.btn-box {
    margin-top: 50px;
}
</style>