export default [
    {
        lang: '1',
        lable: '中文',
        cate: [
            {
                cid: 1,
                lable: '通用场景',
                voice: [{
                    value: 'xiaoyun',
                    label: '标准女声'
                }, {
                    value: 'xiaogang',
                    label: '标准男声'
                }, {
                    value: 'rosa',
                    label: '自然女声'
                }, {
                    value: 'aishuo',
                    label: '自然男声'
                }, {
                    value: 'xiaoxian',
                    label: '亲切女声'
                }, {
                    value: 'stanley',
                    label: '沉稳男声'
                }, {
                    value: 'stella',
                    label: '知性女声'
                }, {
                    value: 'jielidou',
                    label: '治愈童声'
                }, {
                    value: 'ailun',
                    label: '悬疑解说'
                }, {
                    value: 'aifei',
                    label: '激昂解说'
                }, {
                    value: 'yuer',
                    label: '儿童剧女声'
                }, {
                    value: 'maoxiaomei',
                    label: '活力女声'
                }, {
                    value: 'aijia',
                    label: '艾佳'
                }, {
                    value: 'aida',
                    label: '艾达'
                }, {
                    value: 'aningerida',
                    label: '宁儿'
                }, {
                    value: 'ruilin',
                    label: '瑞琳'
                }]
            },
            {
                cid: '2',
                lable: '普通话',
                voice: [{
                    value: 'zhimao',
                    label: '知猫'
                }, {
                    value: 'zhiyuan',
                    label: '知媛'
                }, {
                    value: 'zhigui',
                    label: '知柜'
                }, {
                    value: 'zhiya',
                    label: '知雅'
                }, {
                    value: 'zhiyue',
                    label: '知悦'
                }, {
                    value: 'zhishuo',
                    label: '知硕'
                }, {
                    value: 'zhida',
                    label: '知达'
                }, {
                    value: 'zhistella',
                    label: '知莎'
                }]
            },
            {
                cid: '3',
                lable: '儿童音',
                voice: [
                    {
                        value: 'sitong',
                        label: '思彤'
                    },
                    {
                        value: 'aitong',
                        label: '艾彤'
                    },
                    {
                        value: 'mashu',
                        label: '马树'
                    }
                ]
            },
            {
                cid: '4',
                lable: '卖场广播',
                voice: [
                    {
                        value: 'yaqun',
                        label: '亚群'
                    },
                    {
                        value: 'qiaowei',
                        label: '巧薇'
                    }
                ]
            },
            {
                cid: '5',
                lable: '甜美女声',
                voice: [
                    {
                        value: 'xiaomei',
                        label: '小美'
                    },
                    {
                        value: 'aimei',
                        label: '艾美'
                    }
                ]
            },
            {
                cid: '6',
                lable: '浙普女声',
                voice: [
                    {
                        value: 'aina',
                        label: '艾娜'
                    },
                    {
                        value: 'yina',
                        label: '伊娜'
                    }
                ]
            },
            {
                cid: '7',
                lable: '萝莉女声',
                voice: [
                    {
                        value: 'aiwei',
                        label: '艾薇'
                    },
                    {
                        value: 'aibao',
                        label: '艾宝'
                    },
                    {
                        value: 'xiaobei',
                        label: '小北'
                    }
                ]
            },
            {
                cid: '8',
                lable: '温柔女声',
                voice: [
                    {
                        value: 'ruoxi',
                        label: '若兮'
                    },
                    {
                        value: 'siqi',
                        label: '思琪'
                    },
                    {
                        value: 'aiqi',
                        label: '艾琪'
                    },
                    {
                        value: 'siyue',
                        label: '思悦'
                    },
                    {
                        value: 'aiyue',
                        label: '艾悦'
                    },
                    {
                        value: 'aiya',
                        label: '艾雅'
                    },
                    {
                        value: 'aijing',
                        label: '艾婧'
                    },
                    {
                        value: 'sijing',
                        label: '思婧'
                    }
                ]
            },
            {
                cid: '9',
                lable: '多感情',
                voice: [
                    {
                        value: 'zhimiao_emo',
                        label: '知妙_多情感'
                    },
                    {
                        value: 'zhimi_emo',
                        label: '知米_多情感'
                    },
                    {
                        value: 'zhiyan_emo',
                        label: '知燕_多情感'
                    },
                    {
                        value: 'zhibei_emo',
                        label: '知贝_多情感'
                    },
                    {
                        value: 'zhitian_emo',
                        label: '知甜_多情感'
                    }
                ]
            }
        ]
    }, {
        lang: '2',
        lable: '方言',
        cate: [
            {
                cid: 10,
                lable: '综合',
                voice: [{
                    value: 'shanshan',
                    label: '粤语女声'
                }, {
                    value: 'chuangirl',
                    label: '四川话女声'
                }, {
                    value: 'qingqing',
                    label: '中国台湾话女声'
                }, {
                    value: 'cuijie',
                    label: '东北话女声'
                }, {
                    value: 'xiaoze',
                    label: '湖南重口音男声'
                }, {
                    value: 'tomoka',
                    label: '日语女声'
                }, {
                    value: 'tomoya',
                    label: '日语男声'
                }, {
                    value: 'annie',
                    label: '美语女声'
                }, {
                    value: 'jiajia',
                    label: '粤语女声'
                }, {
                    value: 'indah',
                    label: '印尼语女声'
                }, {
                    value: 'taozi',
                    label: '粤语女声'
                }, {
                    value: 'farah',
                    label: '马来语女声'
                }, {
                    value: 'dahu',
                    label: '东北话男声'
                }, {
                    value: 'ava',
                    label: '美语女声'
                }, {
                    value: 'laotie',
                    label: '东北老铁'
                }, {
                    value: 'laomei',
                    label: '吆喝女声'
                }, {
                    value: 'aikan',
                    label: '天津话男声'
                }, {
                    value: 'tala',
                    label: '菲律宾语女声'
                }, {
                    value: 'tien',
                    label: '越南语女声'
                }, {
                    value: 'becca',
                    label: '美语客服女声'
                }, {
                    value: 'Kyong',
                    label: '韩语女声'
                }, {
                    value: 'masha',
                    label: '俄语女声'
                }, {
                    value: 'camila',
                    label: '西班牙语女声'
                }, {
                    value: 'perla',
                    label: '意大利语女声'
                }, {
                    value: 'kelly',
                    label: '香港粤语女声'
                }]
            },
            {
                cid: '11',
                lable: '美音',
                voice: [
                    {
                        value: 'abby',
                        label: 'Abby'
                    },
                    {
                        value: 'andy',
                        label: 'Andy'
                    }
                ]
            },
            {
                cid: '12',
                lable: '英音',
                voice: [
                    {
                        value: 'eric',
                        label: 'Eric'
                    },
                    {
                        value: 'emily',
                        label: 'Emily'
                    },
                    {
                        value: 'luna',
                        label: 'Luna'
                    },
                    {
                        value: 'luca',
                        label: 'Luca'
                    },
                    {
                        value: 'wendy',
                        label: 'Wendy'
                    },
                    {
                        value: 'olivia',
                        label: 'Olivia'
                    },
                    {
                        value: 'lydia',
                        label: 'Lydia'
                    },
                    {
                        value: 'harry',
                        label: 'Harry'
                    }
                ]
            }
        ]
    }
]