<template>
    <div class="turn-conten">
        <PAGE_HEADER title="文本转语音" />
        <div class="turn-box">
            <div class="turn">
                <TURN ref="turn" />
            </div>

            <div class="turn-btns">
                <el-button type="primary" size="small" @click="gitTurn"
                    >保存</el-button
                >
                <el-button
                    type="primary"
                    size="small"
                    @click="audio_play"
                    v-if="audio"
                    >播放</el-button
                >
            </div>
        </div>
        <audio ref="audio"></audio>

        <el-dialog title="提示" :visible.sync="showModel" width="340px">
            <div>
                <div>
                    恭喜你，文本转语音已合成功，已保存到素材库，请进入(素材库-所有素材)查看
                </div>
                <audio
                    :src="audio"
                    controls
                    width="100%"
                    style="margin-top: 20px"
                ></audio>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showModel = false"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import PAGE_HEADER from "@/components/page_header.vue";
import TURN from "@/components/work/turn.vue";

import { gitAudio } from "@/api/engi";
export default {
    name: "turn",
    components: { PAGE_HEADER, TURN },
    data() {
        return {
            showModel: false,
            audio: "",
            audioInfo: {},
        };
    },

    mounted() {},

    methods: {
        gitTurn() {
            let audioOss = this.$store.state.audio;
            let turn_data = this.$refs.turn.turn;
            if (!turn_data.text) {
                this.$message.error("请输入需要文本类容");
                return;
            }
            // console.log();
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            // console.log();
            gitAudio(turn_data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.showModel = true;
                    this.audio = audioOss + res.data.fileInfo.fileUrl;
                    // this.audio = audioOss + res.data.fileInfo.fileUrl;
                    this.$refs.audio.src = this.audio;
                    // console.log(this.audio.fileUrl);
                } else {
                    this.$message.error("对不起，文本转语音失败！");
                }
                loading.close();
            });
        },
        setTimer() {
            return 1111;
        },
        audio_play() {
            if (!this.audio) {
                this.$alert("请先保存");
            }
            console.log(this.$refs.audio);
            this.$refs.audio.play();
        },
    },
};
</script>

<style lang="less" scoped>
.turn-conten {
    height: 100%;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .turn-box {
        flex: 1;
        height: 0;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        .turn {
            flex: 1;
            height: 0;
        }
        .turn-btns {
            margin-top: 20px;
        }
    }
}
</style>