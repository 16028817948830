<template>
    <div class="page_header">
        <div class="title"><i class="el-icon-arrow-left"></i> {{ title }}</div>
    </div>
</template>

<script>
export default {
    name: "PageHeader",
    props: {
        title: {
            default: "标题",
            type: String,
        },
    },
    data() {
        return {};
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="less" scoped>
.page_header {
    // height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title {
        font-weight: bold;
    }
    i {
        font-weight: bold;
    }
}
</style>